.pic {
  position: absolute;
  width: 300px;
  height: 300px;
  -webkit-animation: fade 2s;
  animation: fade 2s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.animationImg {
  width: 230px;
  height: 153px;
}

.animationFont {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 600;
  text-align: 'center';
  height: 50px;
}

.lastPic {
  position: absolute;
  width: 300px;
  height: 300px;
  -webkit-animation: fadeLast 2.5s;
  animation: fadeLast 2.5s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeLast {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeLast {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}